* {
    box-sizing: border-box;
}


body:not(.landing) {
    font-family: Arial, Helvetica, sans-serif;
    padding: 0 2rem;
    max-width: 800px;
    margin: auto;

    --color-primary: #003399;
    --color-error: #c00000;
    --color-primary-contrast: white;
    --color-accent: #FFCC00;
}

h1, h3 {
    text-align: center;
    color: var(--color-primary);
}

h3+h3 {
    margin-top: -1rem;
}

.dcc-country-badge {
    background-color: var(--color-primary);
    color: var(--color-primary-contrast);
    width: 3rem;
    height: 3rem;
    font-size: 1rem;
    border-radius: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dcc-data {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 0.5rem 0;
}

.dcc-data > span {
    margin: 0.1rem 0;
}

.dcc-data > span:nth-child(1) {
    font-size: 1rem;
    font-weight: bold;
}

.dcc-data > span:nth-child(2) {
    font-size: 0.8rem;
}

.dcc-data > span:nth-child(3) {
    font-size: 1.2rem;
    color: var(--color-primary);
}

.dcc-data--large > span:nth-child(1) {
    font-size: 1.2rem;
}

.dcc-data--large > span:nth-child(2) {
    font-size: 0.8rem;
}

.dcc-data--large > span:nth-child(3) {
    font-size: 1.2rem;
}


.dcc-data--large {
    
}

.color--error {
    color: var(--color-error);
}