.dcc-separator {
    margin: 20px 2rem;
    display: flex;
    justify-content: center;
    height: 11px;

    background-color: var(--color-accent);
    border: 1px solid var(--color-primary);
}

.dcc-separator--image {
    margin: 80px 2rem 40px 2rem;
}

.dcc-separator > div {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 92px;
    height: 92px;
    bottom: 60px;
    border-radius: 100%;

    background-color: var(--color-accent);
    border: 1px solid var(--color-primary);
}

.dcc-separator > div::after {
    display: block;
    content: '';
    position: absolute;
    background-color: var(--color-accent);
    bottom: 22px;
    width: 90px;
    height: 9px;
}

.dcc-separator--image img {
    z-index: 1;
    max-width: 60px;
    max-height: 60px;
}