.dcc-certificate-footer__partners {
    display: flex;
    height: 50px;
    justify-content: space-around;
    margin: 3rem 0 2rem;
}

.dcc-certificate-footer__logo {
    display: block;
    max-width: 200px;
    max-height: 200px;
    margin: 0 auto;
}

.dcc-certificate-footer__partners > img {
    max-height: 50px;
}