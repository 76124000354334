.dcc-certificate-header__flag {
    width: 200px;
    height: 150px;
    margin: 1rem auto;
}

.dcc-certificate-header__flag > img {
    width: 100%;
    height: 100%;
    outline: 1px solid rgba(0,0,0,0.5)
}

.dcc-certificate-header__subtitle {
    text-align: center;
}
